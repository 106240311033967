<template>
  <div class="detail_container">
    <van-notice-bar color="#1989fa" background="#ecf9ff">下单时间：{{ appointmentInfo.dayTime }}</van-notice-bar>
    <img :src="'data:image/png;base64,' + appointmentInfo.invoiceQRCode">
    <van-cell title="订单号" :value="appointmentInfo.recordNum" />
    <van-cell title="姓名" :value="appointmentInfo.name" />
    <van-cell title="科室" :value="appointmentInfo.deptName" />
    <van-cell title="导诊信息" :value="appointmentInfo.remark" />
    <van-cell title="医生" :value="appointmentInfo.doctorName" />
    <van-cell title="就诊日期" :value="appointmentInfo.scheduleDatetime" />
    <van-cell title="就诊时间" :value="appointmentInfo.visitBeginTime + '~' + appointmentInfo.visitEndTime" />
    <van-cell title="流水号" :value="appointmentInfo.orderNum" />
    <van-cell title="总费用" :value="appointmentInfo.cost + '元'" />
    <van-cell v-if="appointmentInfo.isActive == 0 && appointmentInfo.isPay == 0" title="状态" value="未支付" />
    <van-cell v-if="appointmentInfo.isActive !== 0 && appointmentInfo.isPay !== 0" title="状态"
      :value="appointmentInfo.isActive === 2 ? '已取消' : '预约成功'" />
    <div class="qrCode" v-if="appointmentInfo.isPay == 1">
      <img :src="'data:image/png;base64,' + codeImg" style="height: 100%;">
    </div>
    <div class="tip" v-if="appointmentInfo.isPay == 1">生成电子发票十分钟左右后可凭此二维码到自助机内扫描打印电子发票</div>
    <van-notice-bar color="#1989fa" background="#ecf9ff"
      wrapable>温馨提示：如因您自身原因迟到，系统将自动安排您在本班次最后就诊或者退号重新现场挂号</van-notice-bar>
  </div>
</template>

<script>

import { jsonPost, formPost } from "@/baseAPI";
import qs from "qs";
export default {
  name: "Detail",
  data () {
    return {
      appointmentId: "",
      appointmentInfo: {},
      codeImg: '',
      recordNum: "",
      patCardNo: "",
      orderNum: ""
    };
  },
  created () {
    this.appointmentId = this.$route.query.appointmentId;
    this.orderNum = this.$route.query.orderNum;
    this.patCardNo = this.$route.query.patCardNo;
    this.getAppointmentInfo();
  },
  methods: {
    // 电子发票
    async getInvoice () {
      this.$toast.loading({
        duration: 0,
        message: "加载中",
        forbidClick: true
      });

      const { data: res } = await jsonPost("/wxapp/outpatient/viewInvoice", {
        patCardNo: this.patCardNo,
        receiptNum: this.orderNum,
        visitType: "1"
      });
      this.$toast.clear();
      if (res.code === 0) {
        this.codeImg = res.data.invoiceQRCode
      }
    },
    //获取预约详情
    async getAppointmentInfo () {
      this.$toast.loading({
        duration: 0,
        message: "加载中",
        forbidClick: true
      });
      let data = qs.stringify({
        openId: window.localStorage.getItem("openId"),
        appointmentId: this.appointmentId
      });
      const { data: res } = await formPost(
        "/wxapp/appointmentRecord/record/getAppointmentInfo",
        data
      );
      this.$toast.clear();
      if (res.code === 0) {
        this.recordNum = res.data.recordNum
        if (res.data.isPay === 1) {
          this.getInvoice()
        }
        this.appointmentInfo = res.data;
      } else {
        this.$toast.fail(res.msg);
      }
    }
  }
};
</script>

<style lang='less' scope>
.detail_container {
  padding-bottom: 60px;
}

.detail_container .van-notice-bar {
  font-size: 40px;
  padding: 20px 30px;
  line-height: 70px;
}

.qrCode {
  margin: 0 auto;

  // width: 500px;
  // height: 500px;
  img {
    width: 100%;
  }
}

.tip {
  font-size: 40px;
  padding: 0 30px;
  margin-bottom: 30px;
}

.detail_container img {
  display: block;
  margin: 20px auto;
  height: 200px;
  width: 700px;
}

.detail_container .van-cell {
  font-size: 40px;
  padding: 30px;
}

.detail_container .van-cell__value {
  color: #1989fa;
}

.detail_container .van-icon {
  font-size: 40px;
}
</style>

